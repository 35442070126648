import * as React from "react"
import Layout from "../components/Layout/Layout"
import { StoreContext } from "../context/store-context"
import { LineItem } from "../components/Cart/LineItem"
import { formatPrice } from "../utils/format-price"
import Seo from "../components/Core/Seo"
import variables from "../styles/variables"
import breakpoints from "../styles/breakpoints"
import styled from "styled-components"

const Table = styled.table`
  width: 100%;
  margin-top: 20px;

  tr,thead,tbody {
    width: 100%;
  }

  th:nth-child(1), td:nth-child(1) {
    width: 33.5%;
    padding-right: 18px;
    padding-left: ${variables.pagePaddingDesktop};
    text-align: left;
  }

  th:nth-child(2), td:nth-child(2) {
    width: 33.5%;
    padding-right: 18px;
    text-align: left;
  }
  
  th:nth-child(3), td:nth-child(3) {
    width: 22%;
    padding-right: 18px;
    text-align: left;
  }
  
  th:nth-child(4), td:nth-child(4) {
    width: 4%;
    padding-right: 18px;
    text-align: right;
  }
  
  th:nth-child(5), td:nth-child(5) {
    width: 7%;
    text-align: right;
    padding-right: ${variables.pagePaddingDesktop};
  }

  th.totals {
    text-align: right;
  }

  @media (max-width: 1299px) {
    th:nth-child(1), td:nth-child(1) {
      width: 32%;
    }
    
    th:nth-child(2), td:nth-child(2) {
      width: 35%;
    }
    
    th:nth-child(3), td:nth-child(3) {
      width: 17%;
    }
    
    th:nth-child(4), td:nth-child(4) {
      width: 6%;
    }
    
    th:nth-child(5), td:nth-child(5) {
      width: 10%;
    }
  
    .summaryContainer {
      width: calc(33% - ${variables.pagePaddingDesktop});
    }
  }
  
  @media (max-width: 999px) {
    th:nth-child(1), td:nth-child(1) {
      width: 26%;
    }
    
    th:nth-child(2), td:nth-child(2) {
      width: 35%;
    }
    
    th:nth-child(3), td:nth-child(3) {
      width: 17%;
    }
    
    th:nth-child(4), td:nth-child(4) {
      width: 8%;
    }
    
    th:nth-child(5), td:nth-child(5) {
      width: 14%;
    }
  }
  
  @media (max-width: 767px) {
    margin-top: 13px;
  
    th:nth-child(2), td:nth-child(2) {
      width: 53%;
      padding-left: ${variables.pagePaddingMobile};
    }
    
    th:nth-child(4), td:nth-child(4) {
      width: 26%;
    }
    
    th:nth-child(5), td:nth-child(5) {
      width: 21%;
    }

    th.mobileCollapse {
      display: none;
    }

    .summaryContainer {
      width: calc(33% - ${variables.pagePaddingMobile});
    }
  }
`

const Wrap = styled.div`
  padding-bottom: 130px;

  @media (min-width: ${breakpoints.md}) {
    width: 100%;
    padding-bottom: 480px;
  }
`

const EmptyStateContainer = styled.div`
  text-align: center;
  max-width: 48ch;
  margin-left: auto;
  margin-right: auto;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const EmptyStateHeading = styled.h1`
  font-size: ${variables.textL};
  line-height: ${variables.lineHeightL};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textXL};
    line-height: ${variables.lineHeightXL};
  }
`

const MobileHeaderContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const Title = styled.h1`
  margin-left: ${variables.pagePaddingMobile};
  margin-right: ${variables.pagePaddingMobile};
  padding-top: 18px;
  padding-bottom: 32px;
  border-bottom: solid ${variables.colorBlackFG} 1px;
  font-size: ${variables.textL};
  line-height: ${variables.lineHeightL};

  @media (min-width: ${breakpoints.md}) {
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom-width: 0;
    font-size: ${variables.textXL};
    line-height: 50px;
    font-weight: normal;
  }
`

const CartHeader = styled.th`
  font-size: ${variables.textS};
  line-height: ${variables.lineHeightSm};
  font-weight: normal;

  @media (min-width: ${breakpoints.md}) {
    line-height: ${variables.lineHeightS}
  }
`

const SummaryContainer = styled.div`
  width: calc(33% - ${variables.pagePaddingDesktop});
  margin-right: ${variables.pagePaddingDesktop};
  margin-left: auto;
  margin-top: 44px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  border-top: solid ${variables.colorBlackFG} 1px;

  @media (max-width: 999px) {
    width: calc(39% - ${variables.pagePaddingDesktop});
  } 

  @media (max-width: 767px) {
    width: calc(100% - (${variables.pagePaddingMobile} * 2));
    margin-top: 0;
    margin-right: ${variables.pagePaddingMobile};
  }
`

const Summary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Subtotal = styled.span`
  font-size: ${variables.textS};
  line-height: ${variables.lineHeightSm};

  @media (min-width: ${breakpoints.md}) {
    line-height: ${variables.lineHeightS};
  }
`

const SubtotalPrice = styled.span`
  font-size: ${variables.textS};
  line-height: ${variables.lineHeightSm};
  font-family: "Century Schoolbook", serif;

  @media (min-width: ${breakpoints.md}) {
    line-height: ${variables.lineHeightS};
  }
`

const CheckoutButton = styled.button`
  width: 100%;
  text-align: left;
  background-color: ${variables.colorBlackFG};
  color: ${variables.colorWhiteFG};
  padding: 21px;
  margin-top: 45px;
  margin-bottom: 18px;
`

const Message = styled.p`
  font-size: ${variables.textS};
  line-height: ${variables.lineHeightSm};
  font-family: "Century Schoolbook", serif;

  @media (min-width: ${breakpoints.md}) {
    line-height: ${variables.lineHeightS};
  }
`

// @todo add meta title and meta description to SEO component

export default function CartPage() {
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  const handleCheckout = () => {
    window.location.href = checkout.webUrl
  }

  const pageDescription = "Cart: Greg White photographer. Represented by Picture Club. Award winning for his commercial and personal projects, specialising in landscape, technology and automotive photography."

  return (
    <Layout store={true}>
      <Seo
        fullTitle={false}
        path="/cart"
        title="Cart"
        description={pageDescription}
      />
      <Wrap>
        {emptyCart ? (
          <EmptyStateContainer>
            <EmptyStateHeading>Your cart is empty</EmptyStateHeading>
          </EmptyStateContainer>
        ) : (
          <>
            <MobileHeaderContainer>
              <Title>Your cart</Title>
            </MobileHeaderContainer>
            <Table>
              <thead>
                <tr>
                  <th className="mobileCollapse">
                    <Title>Your cart</Title>
                  </th>
                  <CartHeader>Product</CartHeader>
                  <CartHeader className="mobileCollapse">Price</CartHeader>
                  <CartHeader>Qty</CartHeader>
                  <CartHeader>Total</CartHeader>
                </tr>
              </thead>
              <tbody>
                {checkout.lineItems.map((item) => (
                  <LineItem item={item} key={item.id} />
                ))}
              </tbody>
            </Table>
            <SummaryContainer>
              <Summary>
                <Subtotal>Subtotal</Subtotal>
                <SubtotalPrice>
                  {formatPrice(
                    checkout.subtotalPriceV2.currencyCode,
                    checkout.totalPriceV2.amount
                  )}
                </SubtotalPrice>
              </Summary>
              <CheckoutButton
                onClick={handleCheckout}
                disabled={loading}
              >
                Buy now
              </CheckoutButton>
              <Message>Tax included and shipping calculated at checkout.</Message>
            </SummaryContainer>
          </>
        )}
      </Wrap>
    </Layout>
  )
}
