import * as React from "react"
import debounce from "lodash.debounce"
import { StoreContext } from "../../context/store-context"
import { formatPrice } from "../../utils/format-price"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import { Link } from "gatsby"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import variables from "../../styles/variables"

const ContainerTR = styled.tr`
  padding-bottom: 34px;
  margin-top: 18px;

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 0;
    margin-top: 44px;
  }
`

const MobileCollapseTD = styled.td`
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: table-cell;
  }
`

const Title = styled.h2`
  font-size: ${variables.textSm};
  line-height: ${variables.lineHeightSm};
  padding-bottom: 14px;
  font-weight: ${variables.fontWeightBold};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textS};
    line-height: ${variables.lineHeightS};
  }

  @media (min-width: 1000px) {
    font-weight: ${variables.fontWeightR};
    font-size: ${variables.textXL};
    line-height: ${variables.lineHeightXL};
  }
`

const Variant = styled.div`
  font-size: ${variables.textSm};
  line-height: ${variables.lineHeightSm};
  padding-bottom: 21px;

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textS};
    line-height: ${variables.lineHeightS};
  }
`

const RemoveButton = styled.button`
  font-size: ${variables.textSm};
  display: inline-flex;
  align-items: center;
  font-family: "Century Schoolbook", serif;
  border-bottom-width: 1px;

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textS};
  }
`

const MobileImageContainer = styled.div`
  margin-top: 18px;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const TextMobileCollapseTD = styled.td`
  display: none;

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textS};
    line-height: ${variables.lineHeightS};
    display: table-cell;
  }
`

const TextTD = styled.td`
  font-size: ${variables.textSm};
  line-height: ${variables.lineHeightSm};
  font-family: "Century Schoolbook", serif;

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textS};
    line-height: ${variables.lineHeightS};
  }
`

export function LineItem({ item }) {
  const {
    removeLineItem,
    checkout,
    updateLineItem,
    loading,
    productImages
  } = React.useContext(StoreContext)
  const [quantity, setQuantity] = React.useState(item.quantity)


  const imageAttribute = item.customAttributes?.find((attr) => attr.key === "ID")

  const imageAttributeValue = imageAttribute && imageAttribute.value

  const productImage = productImages[imageAttributeValue]

  let variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  }

  if (productImage) {
    variantImage = {
      id: productImage.shopifyId,
      altText: productImage.altText,
      height: productImage.gatsbyImageData.height,
      width: productImage.gatsbyImageData.width,
      originalSrc: productImage.originalSrc,
      src: productImage.src,
    }
  }

  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  )

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  )

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id, imageAttributeValue)
  }

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    300
  )
  // eslint-disable-next-line
  const debouncedUli = React.useCallback((value) => uli(value), [])

  const handleQuantityChange = (value) => {
    if (value !== "" && Number(value) < 1) {
      return
    }
    setQuantity(value)
    if (Number(value) >= 1) {
      debouncedUli(value)
    }
  }

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 456,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  )

  return (
    <ContainerTR>
      <MobileCollapseTD>
        {image && (
          <GatsbyImage
            key={variantImage.src}
            image={image}
            alt={variantImage.altText ?? item.variant.title}
          />
        )}
      </MobileCollapseTD>
      <td>
        <Link to={`/product/${item.variant.product.handle}`}>
          <Title>{item.title}</Title>
        </Link>
        {item.variant.title !== "Default Title" && <Variant>
            {item.variant.title.split(" / ").pop()}{item.variant.title.split(" / ").pop() !== "Print only" ? " frame" : ""}
        </Variant>}
        <div>
          <RemoveButton onClick={handleRemove}>
            Remove
          </RemoveButton>
        </div>
        <MobileImageContainer>
          {image && (
            <GatsbyImage
              key={variantImage.src}
              image={image}
              alt={variantImage.altText ?? item.variant.title}
            />
          )}
        </MobileImageContainer>
      </td>
      <TextMobileCollapseTD>{price}</TextMobileCollapseTD>
      <TextTD>{quantity}</TextTD>
      <TextTD>{subtotal}</TextTD>
    </ContainerTR>
  )
}
